import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import moment from "moment";

import { useTranslation } from "react-i18next";

import styles from "./datePicker.module.css";

interface DatePickerProps {
  setExpenseDateTime: React.Dispatch<React.SetStateAction<string | null>>;
}
export default function DatePick({ setExpenseDateTime }: DatePickerProps) {
  const { t } = useTranslation("common");

  const [expenseDate, setExpenseDate] = useState<Date | null>(new Date());

  const handleFromDate = (date: Date | null) => {
    setExpenseDate(date);
    setExpenseDateTime(moment(date).format("YYYY-MM-DD"));
  };

  return (
    <div className={styles.picker}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MobileDatePicker
          label={t("expenseDate")}
          value={expenseDate}
          inputFormat="DD/MM/YYYY"
          maxDate={new Date()}
          onChange={(newValue) => {
            handleFromDate(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {expenseDate &&
                    new Date(expenseDate)?.toDateString() ===
                      new Date()?.toDateString() ? (
                      <>{t("today")}</>
                    ) : (
                      <>{t("prevtoday")}</>
                    )}
                  </>
                ),
              }}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
}
