import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import moment from "moment";

import { useTranslation } from "react-i18next";

//services
import {
  setRecurringExpensesAPI,
  deleteRecurringExpensesAPI,
  updateRecurringExpensesAPI,
} from "@services/expensesApi";

//styles
import styles from "./recurringExpenses.module.css";

//interfaces
import { categories } from "@interfaces/categories";
import { paymentMethods } from "@interfaces/paymentMethods";

interface RecurringExpenseProps {
  currency: string;
  allcategories: categories[] | undefined;
  paymentMethods: paymentMethods[];
  user_id: string;
  netWorth: string | undefined;
  data: {
    id: string;
    name: string;
    row_id: number | string | null;
    amount: null | string;
    date: null;
    description: string | null;
    payment_ways: {
      label: string;
      id: number;
    }[];
    categories: {
      label: string;
      id: number;
    }[];
  };
}
export default function RecurringExpense({
  data,
  currency,
  allcategories,
  paymentMethods,
  user_id,
  netWorth,
}: RecurringExpenseProps) {
  const { t } = useTranslation("common");
  const [expenseDate, setExpenseDate] = useState<string | null>(data.date);
  const [amount, setAmount] = useState<string | null>(data.amount);
  const [description, setDescription] = useState<string | null>(
    data.description
  );
  const [selectedCat, setSelectedCat] = useState<any>(
    data.categories[1] && data.categories[1].id ? data.categories[1] : null
  );
  const [selectedCatInputValue, setSelectedCatInputValue] = useState("");
  const [selectedPay, setSelectedPay] = useState<any>(
    data.payment_ways[1] && data.payment_ways[1].id
      ? data.payment_ways[1]
      : null
  );
  const [selectedPayInputValue, setSelectedPayInputValue] = useState("");
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(true);
  const [isSaveData, setIsSaveData] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [collapse, setCollapse] = useState(false);

  const categories: any = [];
  allcategories?.forEach((category) => {
    categories.push({
      label: t("radiosSelections." + category.name),
      id: category.id,
    });
  });

  const paymentsMethods: any = [];
  paymentMethods?.forEach((payment) => {
    paymentsMethods.push({
      label: t("radiosSelections." + payment.name),
      id: payment.id,
    });
  });

  const handleFromDate = (date: Date | null) => {
    setExpenseDate(moment(date).format("YYYY-MM-DD"));
  };

  const handleSave = () => {
    if (expenseDate && amount && selectedCat && selectedPay) {
      const req = {
        user_id: user_id,
        amount: amount,
        category_id: selectedCat.id,
        payment_way_id: selectedPay.id,
        expense_date: expenseDate ? new Date(expenseDate) : new Date(),
        description: description ? description : null,
        netWorth: netWorth ? netWorth : null,
      };

      setRecurringExpensesAPI(req).then((response) => {
        if (response && response.insertId) {
          setIsSaveData(true);
          setCollapse(false);
          data.row_id = response.insertId;
        }
      });
    }
  };

  const handleUpdate = () => {
    if (
      data.row_id &&
      user_id &&
      expenseDate &&
      amount &&
      selectedCat &&
      selectedPay
    ) {
      const req = {
        user_id: user_id,
        row_id: data.row_id,
        amount: amount,
        category_id: selectedCat.id,
        payment_way_id: selectedPay.id,
        expense_date: expenseDate ? new Date(expenseDate) : new Date(),
        description: description ? description : null,
        netWorth: netWorth ? netWorth : null,
      };

      updateRecurringExpensesAPI(req).then((response) => {
        if (response.affectedRows >= 1) {
          setIsUpdated(true);
          setTimeout(() => {
            setIsUpdated(false);
          }, 2000);
        }
      });
    }
  };

  const handleDelete = () => {
    if (data.row_id && user_id) {
      const req = {
        row_id: data.row_id,
        user_id: user_id,
      };

      deleteRecurringExpensesAPI(req).then((response) => {
        if (response.affectedRows >= 1) {
          reset();
        }
      });
    }
  };

  const reset = () => {
    setExpenseDate(null);
    setAmount(null);
    setDescription(null);
    setSelectedCat(null);
    setSelectedCatInputValue("");
    setSelectedPay(null);
    setSelectedPayInputValue("");
    setIsSaveBtnDisabled(true);
    data.amount = null;
    data.row_id = null;
    setIsSaveData(false);
  };

  const expandAcc = () => {
    setCollapse(!collapse);
  };

  useEffect(() => {
    if (expenseDate && amount && selectedCat && selectedPay) {
      setIsSaveBtnDisabled(false);
    }
  }, [expenseDate, amount, description, selectedCat, selectedPay]);

  return (
    <Accordion
      className={styles.accordionWrapper}
      expanded={collapse}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        className={styles.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        onClick={expandAcc}
      >
        <Typography style={{ fontSize: "14px" }}>{t(data.name)}</Typography>

        {data.amount || isSaveData ? (
          <div className={styles.active}>{t("settingsPage.active")}</div>
        ) : (
          <div className={styles.inactive}>{t("settingsPage.inactive")}</div>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <div className={styles.recurringWrapper}>
          <TextField
            label={t("amount") + " *"}
            // error={error}
            variant="outlined"
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: (
                <InputAdornment position="end">
                  {currency ? currency : "€"}
                </InputAdornment>
              ),
            }}
            type="number"
            value={amount ? amount : ""}
            onChange={(e) => setAmount(e.target.value)}
          />

          <Autocomplete
            disablePortal
            value={selectedCat}
            options={categories}
            isOptionEqualToValue={(option: any, value: any) =>
              option.id === value.id
            }
            sx={{ width: 300 }}
            onChange={(event: any, newValue: any) => {
              setSelectedCat(newValue);
            }}
            inputValue={selectedCatInputValue}
            onInputChange={(event, newInputValue) => {
              setSelectedCatInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label={t("category") + " *"} />
            )}
          />

          <Autocomplete
            disablePortal
            value={selectedPay}
            options={paymentsMethods}
            isOptionEqualToValue={(option: any, value: any) =>
              option.id === value.id
            }
            sx={{ width: 300 }}
            onChange={(event: any, newValue: any) => {
              setSelectedPay(newValue);
            }}
            inputValue={selectedPayInputValue}
            onInputChange={(event, newInputValue) => {
              setSelectedPayInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label={t("paymentMethod") + " *"} />
            )}
          />

          <TextField
            value={description ? description : ""}
            label={t("Description")}
            variant="outlined"
            onChange={(e) => setDescription(e.target.value)}
          />

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MobileDatePicker
              className={styles.dayselected}
              DialogProps={{
                className: "daypick",
              }}
              label={t("settingsPage.recExpenseDate") + " *"}
              // value={expenseDate}
              value={expenseDate}
              // inputFormat="DD/MM/YYYY"
              inputFormat="DD"
              // maxDate={new Date()}
              onChange={(newValue: any) => {
                handleFromDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {t("settingsPage.repeatEvery")}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {t("settingsPage.dayOftheMonth")}
                  </InputAdornment>
                ),
              }}
            />
          </LocalizationProvider>
        </div>
      </AccordionDetails>
      {data.amount || isSaveData ? (
        <div className={styles.actionsUpdate}>
          <Button
            className={styles.button}
            variant="contained"
            size="medium"
            color="error"
            onClick={handleDelete}
          >
            {t("actions.unset")}
          </Button>
          <Button
            className={styles.button}
            variant="contained"
            size="medium"
            color="info"
            onClick={handleUpdate}
          >
            {t("actions.update")}
          </Button>
        </div>
      ) : (
        <Button
          className={styles.button}
          variant="contained"
          size="medium"
          sx={{ marginLeft: "auto", marginRight: "auto", display: "flex" }}
          // fullWidth
          disabled={isSaveBtnDisabled}
          onClick={handleSave}
        >
          {t("actions.set")}
        </Button>
      )}
      {isUpdated && <Alert severity="success">{t("messages.updated")}</Alert>}
    </Accordion>
  );
}
