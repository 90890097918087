import React, { useContext } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import CoffeeIcon from "@mui/icons-material/Coffee";
import SmokingRoomsIcon from "@mui/icons-material/SmokingRooms";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import StorefrontIcon from "@mui/icons-material/Storefront";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import SdOutlinedIcon from "@mui/icons-material/SdOutlined";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import CasinoIcon from "@mui/icons-material/Casino";
import LiquorIcon from "@mui/icons-material/Liquor";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import BedroomParentIcon from "@mui/icons-material/BedroomParent";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import PaymentsIcon from "@mui/icons-material/Payments";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import DirectionsTransitIcon from "@mui/icons-material/DirectionsTransit";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";

import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../context/themeProvider";

//styles
import styles from "./list.module.css";

interface Data {
  name: string;
  total_expenses: number;
  partner_expenses: number;
}

interface ListProps {
  data: Data[];
  title: string;
  tabInView?: number;
  currency: string;
}

const icons = [
  {
    name: "Other",
    icon: <ShoppingCartCheckoutIcon />,
  },
  {
    name: "Coffee",
    icon: <CoffeeIcon />,
  },
  {
    name: "Cigarettes",
    icon: <SmokingRoomsIcon />,
  },
  {
    name: "Electricity Bills",
    icon: <ElectricBoltIcon />,
  },
  {
    name: "Phone & Internet Bills",
    icon: <PhoneAndroidIcon />,
  },

  {
    name: "Shopping",
    icon: <ShoppingBagIcon />,
  },
  {
    name: "Other Bills",
    icon: <PaymentsIcon />,
  },
  {
    name: "Bank Installments",
    icon: <AccountBalanceWalletIcon />,
  },
  {
    name: "Rent",
    icon: <BedroomParentIcon />,
  },
  {
    name: "Subscription Services",
    icon: <SubscriptionsIcon />,
  },
  {
    name: "Gifts",
    icon: <CardGiftcardIcon />,
  },
  {
    name: "Food & Drink Orders",
    icon: <FastfoodIcon />,
  },
  {
    name: "Night Out",
    icon: <LiquorIcon />,
  },
  {
    name: "Betting & Casino",
    icon: <CasinoIcon />,
  },
  {
    name: "Gas/Diesel/Petrol",
    icon: <LocalGasStationIcon />,
  },
  {
    name: "Heating Bills",
    icon: <DeviceThermostatIcon />,
  },
  {
    name: "Grocery",
    icon: <LocalGroceryStoreIcon />,
  },
  {
    name: "Investments",
    icon: <CurrencyBitcoinIcon />,
  },
  {
    name: "Super Market",
    icon: <StorefrontIcon />,
  },
  {
    name: "Transportation",
    icon: <DirectionsTransitIcon />,
  },
  {
    name: "Travel Costs",
    icon: <ConnectingAirportsIcon />,
  },
  {
    name: "Cash",
    icon: <SavingsOutlinedIcon />,
  },
  {
    name: "Debit Card",
    icon: <CreditCardIcon />,
  },
  {
    name: "Credit Card",
    icon: <PaymentsOutlinedIcon />,
  },
  {
    name: "Bank Deposit",
    icon: <AccountBalanceOutlinedIcon />,
  },
  {
    name: "Revolut",
    icon: <SdOutlinedIcon />,
  },
];

export default function BasicList({
  data,
  title,
  tabInView,
  currency,
}: ListProps) {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation("common");

  const Icon = (name: any) => {
    if (name) {
      return <>{icons.find((element) => element.name === name)?.icon}</>;
    }
    return null;
  };

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <div className={styles.title}>{title}</div>
      <List className={`${theme === "dark" && styles.listBlack}`}>
        {data.map(function (item: Data) {
          return (
            <div key={item.name}>
              <ListItem disablePadding>
                <ListItemButton>
                  {item.total_expenses === 0 && tabInView === 0 ? null : (
                    <>
                      <ListItemIcon className={styles.icon}>
                        {Icon(item.name)}
                      </ListItemIcon>
                      <ListItemText
                        className={styles.listName}
                        //sx={{ maxWidth: "100px" }}
                        primary={t("radiosSelections." + item.name)}
                      />
                    </>
                  )}

                  {tabInView === 1 ? (
                    <>
                      <div style={{ minWidth: "100px" }}>
                        <ListItemText
                          sx={{
                            textAlign: "center",
                            backgroundColor: "#1976d2",
                            color: "#fff",
                            borderRadius: "4px",
                          }}
                          primary={
                            item.partner_expenses +
                            item.total_expenses +
                            (currency ? currency : "€")
                          }
                        />
                        <div className={styles.analysisPartner}>
                          <div className={styles.mine}>
                            {item.total_expenses + (currency ? currency : "€")}
                          </div>
                          <div className={styles.partner}>
                            {item.partner_expenses +
                              (currency ? currency : "€")}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {item.total_expenses !== 0 && (
                        <ListItemText
                          sx={{ textAlign: "right" }}
                          primary={
                            item.total_expenses + (currency ? currency : "€")
                          }
                        />
                      )}
                    </>
                  )}
                </ListItemButton>
              </ListItem>
              <Divider />
            </div>
          );
        })}
      </List>
    </Box>
  );
}
