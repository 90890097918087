import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import HistoryIcon from "@mui/icons-material/History";
import AssessmentIcon from "@mui/icons-material/Assessment";
import Paper from "@mui/material/Paper";

import { ThemeContext } from "../../context/themeProvider";

import { useTranslation } from "react-i18next";

//styles
import styles from "./bottomNavigation.module.css";

export default function FixedBottomNavigation() {
  const theme = useContext(ThemeContext);
  const { t, i18n } = useTranslation("common");
  const [value, setValue] = useState(0);
  return (
    <Box sx={{ pb: 7 }}>
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 9,
          boxShadow: "0px 0px 10px -5px",
        }}
        className="bottom__nav"
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction
            label={t("addExpense")} //"Add Expense"
            component={Link}
            value="/add"
            to="/add"
            icon={<ControlPointIcon />}
          />
          <BottomNavigationAction
            label={t("report")} //"Report"
            component={Link}
            value="/report"
            to="/report"
            icon={<AssessmentIcon />}
          />
          <BottomNavigationAction
            label={t("history")} //"History"
            component={Link}
            value="/history"
            to="/history"
            icon={<HistoryIcon />}
          />
          {/* <BottomNavigationAction
            label="History"
            component={<ActionsDial />}
            // value="/history"
            // to="/history"
            icon={<HistoryIcon />}
          /> */}
          {/* <ActionsDial /> */}
        </BottomNavigation>

        <div
          className={`${styles.copywrites} ${
            theme === "dark" && styles.copywritesBlack
          }`}
        >
          Developed & Designed By{" "}
          <a href="https://github.com/mrakisp" target="_blank">
            Akis Paneras.
          </a>{" "}
          <a href="https://site.mycashtrack.com/" target="_blank">
            <strong>WEBSITE</strong>
          </a>
        </div>
      </Paper>
    </Box>
  );
}
