import React, { useContext } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Colors,
  Title,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../context/themeProvider";

ChartJS.register(ArcElement, Tooltip, Legend, Colors, Title);

interface Data {
  name: string;
  total_expenses: number;
  partner_expenses: number;
}

interface PieProps {
  data: Data[];
  tabInView?: number;
}

export default function BasicList({ data, tabInView }: PieProps) {
  const { t } = useTranslation("common");
  const theme = useContext(ThemeContext);

  const labels: string[] = [];
  const percentage: string[] = [];
  data.forEach((cat: Data) => {
    labels.push(t("radiosSelections." + cat.name));
    if (tabInView && tabInView === 1) {
      percentage.push((cat.total_expenses + cat.partner_expenses).toString());
    } else {
      percentage.push(cat.total_expenses.toString());
    }
  });

  const options = {
    responsive: true,
    color: theme === "dark" ? "#fff" : "#333",
    aspectRatio: 2,
    plugins: {
      legend: {
        position: "bottom" as const,
        // color: "red",
      },
      // title: {
      //   display: true,
      //   color: "red",
      //   text: "Chart.js Line Chart",
      // },
    },
  };

  const pieChartData = {
    labels: labels,
    datasets: [
      {
        label: t("historyPage.TotalExpenses"),
        data: percentage,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgb(4 164 169 / 20%)",
          "rgb(169 14 4 / 20%)",
          "rgb(4 169 121 / 20%)",
          "rgb(157 4 169 / 20%)",
          "rgb(240 33 124 / 20%)",
          "rgb(36 249 61 / 20%)",
          "rgb(236 0 84 / 20%)",
          "rgb(31 0 236 / 20%)",
          "rgb(0 255 148 / 20%)",
          "rgb(216 255 0 / 20%)",
          "rgb(255 0 0 / 20%)",
          "rgb(66 0 255 / 20%)",
          "rgb(34 255 0 / 20%)",
          "rgb(0 30 255 / 20%)",
          "rgb(255 0 177 / 20%)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgb(4 164 169 / 100%)",
          "rgb(169 14 4 / 100%)",
          "rgb(4 169 121 / 100%)",
          "rgb(157 4 169 / 100%)",
          "rgb(240 33 124 / 100%)",
          "rgb(36 249 61 / 100%)",
          "rgb(236 0 84 / 100%)",
          "rgb(31 0 236 / 100%)",
          "rgb(0 255 148 / 100%)",
          "rgb(216 255 0 / 100%)",
          "rgb(255 0 0 / 100%)",
          "rgb(66 0 255 / 100%)",
          "rgb(34 255 0 / 100%)",
          "rgb(0 30 255 / 100%)",
          "rgb(255 0 177 / 100%)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div style={{ maxWidth: "400px", margin: "auto", marginTop: "30px" }}>
      <Pie options={options} data={pieChartData} />
    </div>
  );
}
