import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";

import styles from "./stepperHead.module.css";

// const steps = ["Add Amount", "Category", "Payment Method"];

interface StepperProps {
  activeStep: number;
}
export default function HorizontalLinearStepper({ activeStep }: StepperProps) {
  const { t, i18n } = useTranslation("common");
  const steps = [t("addAmount"), t("category"), t("paymentMethod")];
  const [skipped] = useState(new Set<number>());

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  return (
    <Box sx={{ width: "100%", marginTop: "20px" }}>
      <Stepper
        className={styles.container}
        activeStep={activeStep}
        alternativeLabel
      >
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">{t("optional")}</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel className={styles.title} {...labelProps}>
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
