import React, { useState } from "react";
import moment from "moment";

import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Popover from "@mui/material/Popover";
import InfoIcon from "@mui/icons-material/Info";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";

import { useTranslation } from "react-i18next";

//interfaces
import {
  Data,
  HeadCell,
  Order,
  EnhancedTableProps,
  EnhancedTableToolbarProps,
} from "@interfaces/expenses";

//styles
import styles from "./table.module.css";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells: HeadCell[] = [
  {
    id: "amount",
    numeric: true,
    disablePadding: true,
    label: "Amount",
    visible: true,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
    visible: true,
  },
  {
    id: "category_name",
    numeric: true,
    disablePadding: false,
    label: "Category",
    visible: false,
  },
  {
    id: "payment_way_name",
    numeric: false,
    disablePadding: false,
    label: "Payment Method",
    visible: false,
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Date",
    visible: true,
  },
];

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  const { t } = useTranslation("common");

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {props.headCells.map((headCell) => (
          <React.Fragment key={headCell.id}>
            {headCell.visible && (
              <TableCell
                key={headCell.id}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {t(headCell.label)}
                  {/* {headCell.label} */}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )}
          </React.Fragment>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, handleShowAdditionalInfo, handleDelete } = props;
  const handleShowAdInfo = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleShowAdditionalInfo(event.target.checked);
  };
  const { t } = useTranslation("common");

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "title-popover" : undefined;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        backgroundColor: "#1976d2",
        color: "#fff",
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        // <div>
        <Typography
          sx={{ flex: "1 1 100%", marginTop: "10px;", marginBottom: "5px" }}
          // variant="h6"
          id="tableTitle"
          component="div"
        >
          {t("expensesHistory")}
          {/* Expenses */}
          <IconButton onClick={handleClick}>
            <InfoIcon sx={{ fontSize: "16px", marginBottom: "11px" }} />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Typography sx={{ p: 2 }}>
              {t("expensesHistoryInfo")}
              {/* Last 100 records are visible. If you want to see more records (
              Last 500 ) please download in excel file at the bottom of the page */}
            </Typography>
          </Popover>
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <FormGroup>
          <FormControlLabel
            className={styles.switcher}
            control={<Switch onChange={handleShowAdInfo} />}
            label={t("showMore")} //"Show More"
          />
        </FormGroup>
      )}
    </Toolbar>
  );
}

interface TableProps {
  rows: any;
  handleDelete: (ids: any) => void;
  handleDownload: () => void;
  currency: string;
}
export default function EnhancedTable({
  rows,
  handleDelete,
  handleDownload,
  currency,
}: TableProps) {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof Data>("created_at");
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [page, setPage] = useState(0);
  const [dense] = useState(false);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [headerCols, setHeaderCols] = useState(headCells);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: any) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleShowAdditionalInfo = (value: boolean) => {
    const headCellsArray = headerCols;
    if (value) {
      headCellsArray.forEach((cell) => {
        cell.visible = true;
      });
    } else {
      headCellsArray.forEach((cell) => {
        if (cell.id === "payment_way_name" || cell.id === "category_name")
          cell.visible = false;
      });
    }
    setHeaderCols(headCellsArray);
    setShowAdditionalInfo(value);
  };

  const handleDeleteExpenses = () => {
    handleDelete(selected);
  };
  const isSelected = (id: any) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: "100%", marginTop: "25px" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleShowAdditionalInfo={handleShowAdditionalInfo}
          handleDelete={handleDeleteExpenses}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 400 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              showAdditionalInfo={showAdditionalInfo}
              headCells={headerCols}
            />
            <TableBody className={styles.table}>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          onClick={(event) => handleClick(event, row.id)}
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.amount}
                        {currency ? currency : "€"}
                      </TableCell>
                      <TableCell>
                        {row.description ? row.description : "-"}
                      </TableCell>
                      {showAdditionalInfo && (
                        <TableCell>{row.category_name}</TableCell>
                      )}
                      {showAdditionalInfo && (
                        <TableCell>{row.payment_way_name}</TableCell>
                      )}
                      <TableCell>
                        {moment(row.created_at).format("DD/MM/YYYY")}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <IconButton
          sx={{ position: "absolute", marginTop: "6px", zIndex: "9" }}
          onClick={handleDownload}
        >
          <DownloadForOfflineOutlinedIcon />{" "}
          <span style={{ fontSize: "12px", paddingLeft: "5px" }}>
            Download xlsx
          </span>
        </IconButton>
        <TablePagination
          rowsPerPageOptions={[40]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
