import React from "react";
import { useTranslation } from "react-i18next";
import useLocalStorage from "@hooks/useLocalStorage";

import en from "@assets/en.png";
import gr from "@assets/el.png";

export default function LanguagesSelector() {
  const [t, i18n] = useTranslation("common");
  const [lang, setLang] = useLocalStorage<any>("lang", "en", false);

  const handleLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setLang(language);
  };

  return (
    <>
      <img
        alt="en"
        src={en}
        width="18"
        onClick={() => handleLanguage("en")}
        style={{ marginRight: "10px", cursor: "pointer" }}
      />
      <img
        alt="gr"
        src={gr}
        width="18"
        onClick={() => handleLanguage("gr")}
        style={{ marginRight: "10px", cursor: "pointer" }}
      />
    </>
  );
}
