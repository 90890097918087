import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AddExpenses } from "@pages/addExpenses";
import { History } from "@pages/history";
import { Report } from "@pages/report";
import BottomNavigation from "@components/common/bottomNavigation";
import Donate from "@components/common/donate";
import LoginForm from "@components/login/LoginForm";
import RegisterForm from "@components/login/Register";
import Header from "@components/common/header";
import { Settings } from "@pages/settings";
import useLocalStorage from "@hooks/useLocalStorage";
import Container from "@mui/material/Container";
import { UserContext } from "./context/userProvider";
import { ThemeContext } from "./context/themeProvider";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { getSettingsByUserAPI } from "@services/usersApi";

function App() {
  const [isRegister, setIsRegister] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [userId, setUserId] = useLocalStorage<string>("user", "", true);
  const [theme, setTheme] = useLocalStorage<any>("theme", "light", false);
  const [currency, setCurrency] = useLocalStorage<string>(
    "currency",
    "",
    false
  );
  const [notification, setNotification] = useState(false);

  const Theme = createTheme({
    palette: {
      mode: theme,
    },
  });

  const themeBackground = theme === "dark" ? "#0f0f0f" : "#fff";

  const checkNots = useCallback(async () => {
    getSettingsByUserAPI(userId).then((response: any[]) => {
      if (
        response &&
        response[0] &&
        response[0].linked_email_status &&
        response[0].linked_email_status === "request"
      ) {
        setNotification(true);
      } else {
        setNotification(false);
      }
    });
  }, [userId]);

  useEffect(() => {
    if (!userId) {
      document.body.style.backgroundColor = "#fff";
    } else if (theme === "dark") {
      document.body.style.backgroundColor = "#0f0f0f";
    } else {
      document.body.style.backgroundColor = "#fff";
    }
  }, [themeBackground, userId, theme]);

  return (
    <>
      {!userId ? (
        <>
          {!isRegister ? (
            <LoginForm
              setUserId={setUserId}
              setIsRegister={setIsRegister}
              isRegistered={isRegistered}
            />
          ) : (
            <RegisterForm
              setIsRegister={setIsRegister}
              setIsRegistered={setIsRegistered}
            />
          )}
        </>
      ) : (
        <>
          <ThemeProvider theme={Theme}>
            <ThemeContext.Provider value={theme}>
              <UserContext.Provider value={userId}>
                <Donate />
                <Container
                  maxWidth="md"
                  sx={{ backgroundColor: themeBackground }}
                  className={`app_wrapper ${
                    theme === "dark" && "app_wrapper_dark"
                  }`}
                  // className="app_wrapper"
                >
                  <BrowserRouter>
                    <Header
                      notification={notification}
                      setUserId={setUserId}
                      setTheme={setTheme}
                      theme={theme}
                    />
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <AddExpenses
                            checkNots={checkNots}
                            currency={currency}
                          />
                        }
                      />
                      <Route
                        path="/add"
                        element={
                          <AddExpenses
                            checkNots={checkNots}
                            currency={currency}
                          />
                        }
                      />
                      <Route
                        path="/report"
                        element={
                          <Report checkNots={checkNots} currency={currency} />
                        }
                      />
                      <Route
                        path="/history"
                        element={
                          <History checkNots={checkNots} currency={currency} />
                        }
                      />
                      <Route
                        path="/settings"
                        element={
                          <Settings
                            setCurrency={setCurrency}
                            currency={currency}
                            notification={notification}
                          />
                        }
                      />
                    </Routes>
                    <BottomNavigation />
                  </BrowserRouter>
                </Container>
              </UserContext.Provider>
            </ThemeContext.Provider>
          </ThemeProvider>
        </>
      )}
    </>
  );
}

export default App;
