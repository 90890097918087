import axios from "axios";
import { hostUrl } from "@base/config/config";

export const setExpensesAPI = async (req: {}) => {
  return axios({
    method: "post",
    url: `${hostUrl}/expenses/insert`,
    data: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getExpensesAPI = async (req: {}) => {
  return axios({
    method: "get",
    // url: `${hostUrl}/expenses/${id}`,
    url: `${hostUrl}/expenses`,
    params: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getExpensesSumReportAPI = async (req: {}) => {
  return axios({
    method: "get",
    url: `${hostUrl}/expenses/sum/expensesSum`,
    params: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getExpensesSumPartnerReportAPI = async (req: {}) => {
  return axios({
    method: "get",
    url: `${hostUrl}/expenses/sum/expensesSumPartner`,
    params: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getExpensesSumReportByMonthAPI = async (req: {}) => {
  return axios({
    method: "get",
    url: `${hostUrl}/expenses/sum/expensesSumByMonth`,
    params: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getExpensesSumByCategoryReportAPI = async (req: {}) => {
  return axios({
    method: "get",
    url: `${hostUrl}/expenses/sum/expensesSumByCategory`,
    params: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getPartnerExpensesSumByCategoryReportAPI = async (req: {}) => {
  return axios({
    method: "get",
    url: `${hostUrl}/expenses/sum/partnerexpensesSumByCategory`,
    params: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getExpensesSumByPaymentReportAPI = async (req: {}) => {
  return axios({
    method: "get",
    url: `${hostUrl}/expenses/sum/expensesSumByPayment`,
    params: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getPartnerExpensesSumByPaymentReportAPI = async (req: {}) => {
  return axios({
    method: "get",
    url: `${hostUrl}/expenses/sum/partnerexpensesSumByPayment`,
    params: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const deleteExpenseAPI = async (req: {}) => {
  return axios({
    method: "post",
    url: `${hostUrl}/expenses/deletesingle`,
    data: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const deleteExpensesAPI = async (req: {}) => {
  return axios({
    method: "post",
    url: `${hostUrl}/expenses/deletemass`,
    data: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

//recurring
export const getRecExpensesAPI = async (req: {}) => {
  return axios({
    method: "get",
    url: `${hostUrl}/recexpenses`,
    params: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const setRecurringExpensesAPI = async (req: {}) => {
  return axios({
    method: "post",
    url: `${hostUrl}/recexpenses/insertrecurring`,
    data: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const deleteRecurringExpensesAPI = async (req: {}) => {
  return axios({
    method: "post",
    url: `${hostUrl}/recexpenses/deleterecurring`,
    data: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const updateRecurringExpensesAPI = async (req: {}) => {
  return axios({
    method: "post",
    url: `${hostUrl}/recexpenses/updaterecurring`,
    data: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
