import axios from "axios";
import { hostUrl } from "@base/config/config";

export const registerAPI = async (req: {}) => {
  return axios({
    method: "post",
    url: `${hostUrl}/users/register`,
    data: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const loginAPI = async (req: {}) => {
  return axios({
    method: "post",
    url: `${hostUrl}/users/login`,
    data: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const updateUserAPI = async (req: {}) => {
  return axios({
    method: "post",
    url: `${hostUrl}/users/update`,
    data: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getSettingsByUserAPI = async (id: number | string) => {
  return axios({
    method: "get",
    url: `${hostUrl}/users/${id}`,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getPartnerSettingsByUserAPI = async (email: string) => {
  return axios({
    method: "get",
    url: `${hostUrl}/users/partnerSettings/${email}`,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const setSettingsByUserAPI = async (req: {}) => {
  return axios({
    method: "post",
    url: `${hostUrl}/users/updatesettings`,
    data: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const checkUserPswAPI = async (req: {}) => {
  return axios({
    method: "post",
    url: `${hostUrl}/users/check`,
    data: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const linkEmailAPI = async (req: {}) => {
  return axios({
    method: "post",
    url: `${hostUrl}/users/link`,
    data: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
export const linkEmailPartnerAPI = async (req: {}) => {
  return axios({
    method: "post",
    url: `${hostUrl}/users/linkPartner`,
    data: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const sendEmailToPartnerAPI = async (req: {}) => {
  return axios({
    method: "post",
    url: `${hostUrl}/mail`,
    data: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const unlinkEmailAPI = async (req: {}) => {
  return axios({
    method: "post",
    url: `${hostUrl}/users/unlink`,
    data: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const unlinkPartnerEmailAPI = async (req: {}) => {
  return axios({
    method: "post",
    url: `${hostUrl}/users/unlinkPartner`,
    data: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const approveEmailAPI = async (req: {}) => {
  return axios({
    method: "post",
    url: `${hostUrl}/users/approve`,
    data: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const approvePartnerEmailAPI = async (req: {}) => {
  return axios({
    method: "post",
    url: `${hostUrl}/users/approvePartner`,
    data: req,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
