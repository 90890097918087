import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTranslation } from "react-i18next";

interface TabsProps {
  setTabInView: React.Dispatch<React.SetStateAction<number>>;
  tabsData: {
    name: string;
    icon?: JSX.Element;
  }[];
}
export default function TabsWithIcon({ setTabInView, tabsData }: TabsProps) {
  const { t } = useTranslation("common");
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setTabInView(newValue);
  };

  return (
    <Tabs
      centered
      value={value}
      onChange={handleChange}
      aria-label="icon label tabs"
    >
      {tabsData.map((tab) => (
        <Tab
          key={tab.name}
          icon={tab.icon}
          label={t("settingsPage." + tab.name)}
          // label={tab.name}
        />
      ))}
    </Tabs>
  );
}
