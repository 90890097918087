import React, { useState, useContext } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";

import { ThemeContext } from "../../context/themeProvider";
import { useTranslation } from "react-i18next";

//styles
import styles from "./radioList.module.css";

//interfaces
import { categories } from "@interfaces/categories";
import { paymentMethods } from "@interfaces/paymentMethods";

interface RadioListProps {
  handleSelection: React.Dispatch<React.SetStateAction<string>>;
  data: categories[] | paymentMethods[];
  label: string;
  value: string;
}

export default function RadioList({
  data,
  handleSelection,
  label,
  value,
}: RadioListProps) {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation("common");
  const [searchCategory, setSearchCategory] = useState<string>("");

  const handleSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const searchedValue = e?.target.value.toLowerCase();

    if (searchedValue && searchedValue.length > 0) {
      setSearchCategory(searchedValue);
    } else {
      setSearchCategory("");
    }
  };

  return (
    <FormControl>
      <FormLabel className={styles.title}>{label}</FormLabel>
      {data && data.length > 6 && (
        <TextField
          label={`${t("search")} ${label}`}
          variant="outlined"
          size="small"
          sx={{ marginTop: "8px" }}
          onChange={(e) => handleSearch(e)}
        />
      )}
      <RadioGroup
        className={`${styles.radioList} ${
          theme === "dark" && styles.radioListBlack
        }`}
        onChange={(e) => handleSelection(e.target.value)}
        defaultValue={1}
        value={value}
      >
        {data.map(function (item: categories | paymentMethods) {
          return (
            <FormControlLabel
              key={item.id}
              value={item.id}
              control={<Radio />}
              label={t("radiosSelections." + item.name)}
              className={
                searchCategory !== "" &&
                !t("radiosSelections." + item.name)
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
                  .includes(
                    searchCategory
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  )
                  ? styles.hidden
                  : ""
              }
              // label={item.name}
              // className={
              //   searchCategory !== "" &&
              //   !item.name.toLowerCase().includes(searchCategory)
              //     ? styles.hidden
              //     : ""
              // }
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
