import React, { useContext } from "react";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

import { useTranslation } from "react-i18next";

import { ThemeContext } from "../../context/themeProvider";

//styles
import styles from "./progress.module.css";

function LinearProgressWithLabel(
  props: LinearProgressProps & {
    value: number;
    total: string;
    limit: string;
    period: string;
    isnet?: string;
    isinmonth?: string;
    currency: string;
  }
) {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation("common");

  const Text = () => {
    let message = "";
    if (props.value < 100) {
      if (props.isnet) {
        message = `${t("reportPage.wellDone")} <strong>${
          parseFloat(props.limit) - parseFloat(props.total)
        }${props.currency ? props.currency : "€"}</strong> ${
          props.isinmonth ? `${t("reportPage.soFar")}` : ""
        }`;
      } else {
        message = `${t("reportPage.spended")} <strong>${Math.round(
          props.value
        )}%</strong> ${t("reportPage.settedLimit")} ( <strong>${props.limit}${
          props.currency ? props.currency : "€"
        }</strong>/${props.period} )`;
      }
    } else if (props.value === 100) {
      if (props.isnet) {
        message = `${t("reportPage.allIncome")} :( `;
      } else {
        message = `${t("reportPage.reachedLimit")} ( <strong>${props.limit}${
          props.currency ? props.currency : "€"
        }</strong>/${props.period} )`;
      }
    } else {
      if (props.isnet) {
        message = `${t("reportPage.spended")} <strong>${Math.abs(
          parseFloat(props.limit) - parseFloat(props.total)
        )}${props.currency ? props.currency : "€"}</strong> ${t(
          "reportPage.moreThanIncome"
        )} :( `;
      } else {
        message = `${t("reportPage.overcomeLimit")} ( <strong>${props.limit}${
          props.currency ? props.currency : "€"
        }</strong>/${props.period} ) ${t("reportPage.by")} <strong>${
          Math.round(props.value) - 100
        }%</strong>`;
      }
    }

    return (
      <div
        className={`${styles.message} ${
          theme === "dark" && styles.messageBlack
        }`}
        dangerouslySetInnerHTML={{ __html: message }}
      ></div>
    );
  };
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Text />
      </Box>
    </Box>
  );
}

interface ProgressProps {
  limit: string;
  total: string;
  period: string;
  exludedSum?: string;
  isnet?: boolean | undefined;
  isinmonth?: boolean | undefined;
  partnerNetWorth?: string | undefined;
  tabInView?: number;
  currency: string;
}
export default function LinearWithValueLabel({
  limit,
  total,
  period,
  isnet,
  isinmonth,
  partnerNetWorth,
  tabInView,
  currency,
}: ProgressProps) {
  const { t } = useTranslation("common");
  const progress = (parseFloat(total) / parseFloat(limit)) * 100;
  const theme = useContext(ThemeContext);
  return (
    <Box sx={{ width: "100%", marginTop: "20px", textAlign: "center" }}>
      {tabInView === 1 && !partnerNetWorth ? (
        <div className={`${theme === "dark" && styles.netWorthPartnerError}`}>
          {t("reportPage.cannotCalculate")}
          <br />
          {t("reportPage.partnerMessage")}
        </div>
      ) : (
        <LinearProgressWithLabel
          currency={currency}
          value={progress}
          total={total}
          limit={limit}
          period={period}
          isnet={isnet ? isnet.toString() : ""}
          isinmonth={isinmonth ? isinmonth.toString() : ""}
          className={`${styles.progressBars} ${
            progress > 100 && styles.overlimit
          } ${progress === 100 && styles.reachlimit} ${
            progress < 100 && !isinmonth && isnet && styles.economyAchivied
          }`}
        />
      )}
    </Box>
  );
}
