import React, { useEffect, useState, useContext, useCallback } from "react";
import moment from "moment";
import PersonIcon from "@mui/icons-material/Person";
import WcIcon from "@mui/icons-material/Wc";
import { UserContext } from "../context/userProvider";

import { useTranslation } from "react-i18next";

// import { setLocalStorageUtil,getLocalStorageUtil } from "../utils/setGetLocalStorage";

//styles
import styles from "./report.module.css";

//components
import DatePicker from "@components/common/datePicker";
import List from "@components/report/list";
import Progress from "@components/report/progress";
import Tabs from "@components/common/tabs";
import Pie from "@components/report/pie";

//services
import {
  getExpensesSumReportAPI,
  getExpensesSumByCategoryReportAPI,
  getExpensesSumByPaymentReportAPI,
  getExpensesSumPartnerReportAPI,
  getPartnerExpensesSumByCategoryReportAPI,
  getPartnerExpensesSumByPaymentReportAPI,
} from "@services/expensesApi";

//interfaces
import { usersSettings } from "@interfaces/users";

import {
  getSettingsByUserAPI,
  getPartnerSettingsByUserAPI,
} from "@services/usersApi";

const TabsData = [
  { name: "PERSONAL", icon: <PersonIcon /> },
  { name: "WITH PARTNER", icon: <WcIcon /> },
];

interface Data {
  name: string;
  total_expenses: number;
  partner_expenses: number;
}
interface Props {
  checkNots: () => void;
  currency: string;
}
export function Report({ checkNots, currency }: Props) {
  const { t } = useTranslation("common");
  const user_id = useContext(UserContext);
  const [expensesSum, setExpensesSum] = useState(0);
  const [expensesSumWithPartner, setExpensesSumWithPartner] = useState(0);
  const [expensesSumByCat, setExpensesSumByCat] = useState<Data[]>([]);
  const [partnerExpensesSumByCat, setPartnerExpensesSumByCat] = useState<
    Data[]
  >([]);
  const [expensesSumByPayment, setExpensesSumByPayment] = useState<Data[]>([]);
  const [partnerSumByPayment, setPartnerSumByPayment] = useState<Data[]>([]);
  const [fromValue, setFromValue] = useState<string | null>(null);
  const [toValue, setToValue] = useState<string | null>(null);
  const [dateFromPicker, setDateFromPicker] = useState(false);
  const [netWorth, setNetWorth] = useState<string>();
  const [partnerNetWorth, setPartnerNetWorth] = useState<string>();
  const [linkedEmail, setLinkedEmail] = useState<string>();
  const [isPeriod, setIsPeriod] = useState<string>();
  const [isCurrentMonth, setIsCurrentMonth] = useState(false);
  const [isCurrentDay, setIsCurrentDay] = useState(false);
  const [isInMonth, setIsInMonth] = useState(false);
  const [tabInView, setTabInView] = useState<number>(0);
  const [partnerId, setPartnerId] = useState<string>();

  const getExpensesReport = useCallback(
    async (data: any) => {
      if (data.id) {
        getExpensesSumReportAPI(data).then((response: any) => {
          if (response && response.length > 0) {
            setExpensesSum(response[0].total_expenses);
          } else {
            setExpensesSum(0);
          }
        });
        getExpensesSumByCategoryReportAPI(data).then((response: []) => {
          if (response) {
            const sortedCategories = sortBySum(response);
            setExpensesSumByCat(sortedCategories);
            if (partnerId) getPartnersCategoryReport(sortedCategories, data);
          }
        });
        getExpensesSumByPaymentReportAPI(data).then((response: []) => {
          if (response) {
            const sortedPayments = sortBySum(response);
            setExpensesSumByPayment(sortedPayments);
            if (partnerId) getPartnersPaymentReport(sortedPayments, data);
          }
        });
      }
      if (partnerId) {
        getExpensesSumPartnerReportAPI(data).then((response: any) => {
          if (response && response.length > 0) {
            setExpensesSumWithPartner(response[0].total_expenses);
          } else {
            setExpensesSumWithPartner(0);
          }
        });
      }
    },
    [partnerId]
  );

  const getPartnersCategoryReport = (data: any, request: {}) => {
    const categories = data;
    categories.forEach((cat: Data) => {
      if (!cat.partner_expenses) {
        cat.partner_expenses = 0;
      }
    });
    getPartnerExpensesSumByCategoryReportAPI(request).then((response: []) => {
      if (response && response.length > 0) {
        const categories = data;
        response.forEach((category: Data) => {
          const existinCategory = categories.find(
            (cat: any) => cat.name === category.name
          );
          if (existinCategory) {
            existinCategory.partner_expenses = category.total_expenses
              ? category.total_expenses
              : 0;
          } else {
            categories.push({
              name: category.name,
              total_expenses: 0,
              partner_expenses: category.total_expenses
                ? category.total_expenses
                : 0,
            });
          }
        });
        categories.forEach((category: Data) => {
          if (!category.partner_expenses) {
            category.partner_expenses = 0;
          }
        });

        setPartnerExpensesSumByCat(categories);
      } else {
        setPartnerExpensesSumByCat(categories);
      }
    });
  };

  const getPartnersPaymentReport = (data: any, request: {}) => {
    const payments = data;
    payments.forEach((pay: Data) => {
      if (!pay.partner_expenses) {
        pay.partner_expenses = 0;
      }
    });
    getPartnerExpensesSumByPaymentReportAPI(request).then((response: []) => {
      if (response && response.length > 0) {
        const payments = data;
        response.forEach((payment: Data) => {
          const existinPayment = payments.find(
            (pay: any) => pay.name === payment.name
          );
          if (existinPayment) {
            existinPayment.partner_expenses = payment.total_expenses
              ? payment.total_expenses
              : 0;
          } else {
            payments.push({
              name: payment.name,
              total_expenses: 0,
              partner_expenses: payment.total_expenses
                ? payment.total_expenses
                : 0,
            });
          }
        });
        payments.forEach((category: Data) => {
          if (!category.partner_expenses) {
            category.partner_expenses = 0;
          }
        });

        setPartnerSumByPayment(payments);
      } else {
        setPartnerSumByPayment(payments);
      }
    });
  };

  const getPartnerSettings = (email: string) => {
    if (email) {
      getPartnerSettingsByUserAPI(email).then((response: usersSettings[]) => {
        if (response && response.length > 0) {
          if (response[0].net_worth) {
            setPartnerNetWorth(response[0].net_worth);
          }
          if (response[0].id) {
            setPartnerId(response[0].id);
          }
        }
      });
    }
  };

  const sortBySum = (data: any[]) => {
    data.sort((a, b) => {
      if (a.total_expenses < b.total_expenses) {
        return 1;
      }
      if (a.total_expenses > b.total_expenses) {
        return -1;
      }
      return 0;
    });

    return data;
  };

  const changeFromDate = (date: string) => {
    setFromValue(date);
    if (toValue && date) {
      const data = {
        id: user_id,
        email: linkedEmail,
        partnerId: partnerId,
        dateFrom: moment(date).format("YYYY-MM-DD"),
        dateTo: moment(toValue).format("YYYY-MM-DD"),
      };
      getExpensesReport(data);
      setDateFromPicker(true);

      if (
        moment(date).diff(moment(toValue), "months", true) ===
        -0.967741935483871
      ) {
        setIsCurrentMonth(true);
        setIsCurrentDay(false);
      } else if (moment(date).diff(moment(toValue), "days", true) === 0) {
        setIsCurrentDay(true);
        setIsCurrentMonth(false);
      } else {
        setIsCurrentMonth(false);
        setIsCurrentDay(false);
      }
      setIsInMonth(false);
      setIsPeriod("");
    }
  };

  const changeToDate = (date: string) => {
    setToValue(date);
    if (fromValue && date) {
      const data = {
        id: user_id,
        email: linkedEmail,
        partnerId: partnerId,
        dateFrom: moment(fromValue).format("YYYY-MM-DD"),
        dateTo: moment(date).format("YYYY-MM-DD"),
      };
      getExpensesReport(data);
      setDateFromPicker(true);

      if (
        moment(fromValue).diff(moment(date), "months", true) ===
        -0.967741935483871
      ) {
        setIsCurrentMonth(true);
        setIsCurrentDay(false);
      } else if (moment(fromValue).diff(moment(date), "days", true) === 0) {
        setIsCurrentDay(true);
        setIsCurrentMonth(false);
      } else {
        setIsCurrentMonth(false);
        setIsCurrentDay(false);
      }
      setIsInMonth(false);
      setIsPeriod("");
    }
  };

  const handleToday = useCallback(async () => {
    // const handleToday = () => {
    const date = new Date();
    const data = {
      id: user_id,
      email: linkedEmail,
      partnerId: partnerId,
      dateFrom: moment(date).format("YYYY-MM-DD"),
      dateTo: moment(date).format("YYYY-MM-DD"),
    };
    getExpensesReport(data);
    setDateFromPicker(false);
    setIsCurrentDay(true);
    setIsCurrentMonth(false);
    setIsInMonth(false);
    setIsPeriod("today");
    // };
  }, [getExpensesReport, linkedEmail, partnerId, user_id]);

  const handleThisMonth = useCallback(async () => {
    // const handleThisMonth = () => {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const data = {
      id: user_id,
      email: linkedEmail,
      partnerId: partnerId,
      dateFrom: moment(firstDay).format("YYYY-MM-DD"),
      dateTo: moment(lastDay).format("YYYY-MM-DD"),
    };
    getExpensesReport(data);

    setDateFromPicker(false);
    setIsCurrentMonth(true);
    setIsInMonth(true);
    setIsCurrentDay(false);
    setIsPeriod("thisMonth");
    // };
  }, [linkedEmail, partnerId, user_id, getExpensesReport]);

  const handleLastMonth = useCallback(async () => {
    // const handleLastMonth = () => {
    const date = new Date();
    const firstDay = new Date(
      date.getFullYear() - (date.getMonth() > 0 ? 0 : 1),
      (date.getMonth() - 1 + 12) % 12,
      1
    );
    const lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
    const data = {
      id: user_id,
      email: linkedEmail,
      partnerId: partnerId,
      dateFrom: moment(firstDay).format("YYYY-MM-DD"),
      dateTo: moment(lastDay).format("YYYY-MM-DD"),
    };
    getExpensesReport(data);

    setDateFromPicker(false);
    setIsCurrentDay(false);
    setIsPeriod("lastMonth");

    if (moment(firstDay).diff(moment(lastDay), "months", true)) {
      setIsCurrentMonth(true);
    } else {
      setIsCurrentMonth(false);
    }
    setIsInMonth(false);
    // };
  }, [getExpensesReport, linkedEmail, partnerId, user_id]);

  useEffect(() => {
    if (!partnerId) {
      getSettingsByUserAPI(user_id).then((response: usersSettings[]) => {
        if (response && response.length > 0) {
          if (response[0].net_worth) {
            setNetWorth(response[0].net_worth);
          }
          if (response[0].linked_email) {
            setLinkedEmail(response[0].linked_email);
            if (!partnerId) getPartnerSettings(response[0].linked_email);
          }
          handleThisMonth();
          checkNots();
        }
      });
    }
  }, [checkNots, user_id, handleThisMonth, partnerId]);

  useEffect(() => {
    if (tabInView === 1) {
      if (isPeriod === "today") {
        handleToday();
      } else if (isPeriod === "lastMonth") {
        handleLastMonth();
      } else if (isPeriod === "thisMonth") {
        handleThisMonth();
      } else if (fromValue && toValue) {
        const data = {
          email: linkedEmail,
          dateFrom: moment(fromValue).format("YYYY-MM-DD"),
          dateTo: moment(toValue).format("YYYY-MM-DD"),
        };
        getExpensesReport(data);
      }
    } else {
      setExpensesSumWithPartner(0);
    }
  }, [
    tabInView,
    getExpensesReport,
    handleLastMonth,
    handleThisMonth,
    fromValue,
    isPeriod,
    linkedEmail,
    toValue,
    handleToday,
  ]);

  return (
    <div className="container__page">
      <DatePicker
        changeFromDate={changeFromDate}
        changeToDate={changeToDate}
        handleToday={handleToday}
        handleThisMonth={handleThisMonth}
        handleLastMonth={handleLastMonth}
        dateFromPicker={dateFromPicker}
        isCurrentMonth={isCurrentMonth}
        isCurrentDay={isCurrentDay}
      />
      {partnerId && (
        <div className={styles.tabs}>
          <Tabs tabsData={TabsData} setTabInView={setTabInView} />
        </div>
      )}
      {expensesSum || (expensesSumWithPartner && tabInView === 1) ? (
        <>
          <div className={styles.totals}>
            {expensesSumWithPartner && tabInView === 1
              ? expensesSum + expensesSumWithPartner
              : expensesSum}
            <>{currency ? currency : "€"}</>
          </div>
          {linkedEmail && tabInView === 1 && (
            <>
              <div className={styles.parterSum}>
                <span className={styles.personal}>
                  {t("reportPage.you")}: {expensesSum ? expensesSum : 0}
                  {currency ? currency : "€"}
                </span>
                <span className={styles.partner}>
                  {t("reportPage.partner")}:{" "}
                  {expensesSumWithPartner ? expensesSumWithPartner : 0}
                  {currency ? currency : "€"}
                </span>
              </div>
            </>
          )}
          {isCurrentMonth && netWorth && expensesSum && (
            <Progress
              limit={
                partnerNetWorth && tabInView === 1
                  ? (
                      parseFloat(partnerNetWorth) + parseFloat(netWorth)
                    ).toString()
                  : netWorth
              }
              total={
                expensesSumWithPartner && tabInView === 1
                  ? (expensesSumWithPartner + expensesSum).toString()
                  : expensesSum.toString()
              }
              period={"Month"}
              isnet
              isinmonth={isInMonth}
              partnerNetWorth={partnerNetWorth}
              tabInView={tabInView}
              currency={currency}
            />
          )}
          <Pie
            tabInView={tabInView}
            data={
              partnerExpensesSumByCat && tabInView === 1
                ? partnerExpensesSumByCat
                : expensesSumByCat
            }
          />
          <List
            data={
              partnerExpensesSumByCat && tabInView === 1
                ? partnerExpensesSumByCat
                : expensesSumByCat
            }
            title={t("reportPage.expensesByCat")} //{"Expenses by Category"}
            tabInView={tabInView}
            currency={currency}
          />

          <List
            data={
              partnerSumByPayment && tabInView === 1
                ? partnerSumByPayment
                : expensesSumByPayment
            }
            title={t("reportPage.expensesByPay")} //{"Expenses by Payment Method"}
            tabInView={tabInView}
            currency={currency}
          />
        </>
      ) : (
        <div className={styles.noresults}>{t("reportPage.noExpenses")}</div>
      )}
    </div>
  );
}
