import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import * as EmailValidator from "email-validator";

import { useTranslation } from "react-i18next";

import {
  linkEmailAPI,
  unlinkEmailAPI,
  linkEmailPartnerAPI,
  unlinkPartnerEmailAPI,
  approveEmailAPI,
  approvePartnerEmailAPI,
  sendEmailToPartnerAPI,
} from "@services/usersApi";

//styles
import styles from "./linkAccount.module.css";

interface LinkAccountProps {
  partnerEmail: string | undefined;
  userId: string;
  partnerEmailStatus: string | undefined;
  currentUserEmail: string | undefined;
}
export default function LinkAccount({
  userId,
  partnerEmail,
  partnerEmailStatus,
  currentUserEmail,
}: LinkAccountProps) {
  const { t } = useTranslation("common");
  const [currentUseremail, setCurrentUseremail] = useState(currentUserEmail);
  const [email, setEmail] = useState(partnerEmail);
  const [partnerEmailStatusMessage, setPartnerEmailStatusMessage] =
    useState(partnerEmailStatus);
  const [emailError, setEmailError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [requestedEmailNotExists, setRequestedEmailNotExists] = useState(false);
  const [requestSended, setRequestSended] = useState(false);

  const handlePartnerEmail = (value: any) => {
    setEmailError(false);
    setRequestedEmailNotExists(false);
    setEmail(value);
    if (value && value.length > 5) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const handleLinkEmail = () => {
    const isEmailValid = email && EmailValidator.validate(email);
    if (!isEmailValid) {
      setEmailError(true);
      return;
    } else {
      const req = {
        id: userId,
        email: email,
      };
      linkEmailAPI(req).then((response: any) => {
        if (response && response.affectedRows >= 1) {
          sendRequestToPartner();
        } else {
          setRequestedEmailNotExists(true);
        }
      });
    }
  };

  const sendRequestToPartner = () => {
    const req = {
      cur_user_email: currentUseremail,
      partnerEmail: email,
    };
    linkEmailPartnerAPI(req).then((response: any) => {
      if (response && response.affectedRows >= 1) {
        setRequestSended(true);
        setPartnerEmailStatusMessage("pending");
        sendEmailToPartner();
      }
    });
  };

  const sendEmailToPartner = () => {
    const req = {
      cur_user_email: currentUseremail,
      partnerEmail: email,
    };
    sendEmailToPartnerAPI(req).then((response: any) => {});
  };

  const handleUnLinkEmail = () => {
    const req = {
      id: userId,
    };
    unlinkEmailAPI(req).then((response: any) => {
      if (response.affectedRows >= 1) {
        setEmail("");
        setPartnerEmailStatusMessage("");
      }
    });
    const req1 = {
      email: email,
    };
    unlinkPartnerEmailAPI(req1).then((response: any) => {});
  };

  const handleApproveEmail = () => {
    const req = {
      id: userId,
    };
    approveEmailAPI(req).then((response: any) => {
      if (response.affectedRows >= 1) {
        setPartnerEmailStatusMessage("approved");
      }
    });
    const req1 = {
      email: email,
    };
    approvePartnerEmailAPI(req1).then((response: any) => {});
  };

  useEffect(() => {
    setEmail(partnerEmail);
    setPartnerEmailStatusMessage(partnerEmailStatus);
    setCurrentUseremail(currentUserEmail);
  }, [partnerEmail, partnerEmailStatus, currentUserEmail]);

  return (
    <div className={styles.linkedWrapper}>
      <TextField
        label={t("settingsPage.partnerEmail")} //"Partner Email"
        variant="outlined"
        error={emailError}
        value={email ? email : ""}
        onChange={(e) => handlePartnerEmail(e.target.value)}
      />
      <div className={styles.actions}>
        {!partnerEmailStatusMessage && (
          <Button
            disabled={isDisabled}
            variant="contained"
            size="medium"
            onClick={handleLinkEmail}
          >
            {t("actions.link")}
          </Button>
        )}
        {partnerEmailStatusMessage &&
          (partnerEmailStatusMessage === "pending" ||
            partnerEmailStatusMessage === "approved" ||
            partnerEmailStatusMessage === "request") && (
            <Button
              variant="outlined"
              color="error"
              size="medium"
              onClick={handleUnLinkEmail}
            >
              {partnerEmailStatusMessage &&
              partnerEmailStatusMessage === "request"
                ? t("actions.decline")
                : t("actions.unlink")}
            </Button>
          )}
        {partnerEmailStatusMessage &&
          partnerEmailStatusMessage === "request" && (
            <Button
              variant="outlined"
              size="medium"
              color="success"
              onClick={handleApproveEmail}
            >
              {t("actions.approve")}
            </Button>
          )}
      </div>
      {requestedEmailNotExists && (
        <Alert severity="error">
          {t("warnings.partnerEmailWarn")}
          {/* This email does not exists or its already linked with another user! */}
        </Alert>
      )}
      {requestSended && (
        <Alert severity="success">
          {" "}
          {t("warnings.reqSend")}
          {/* Request sended and pending approval. */}
        </Alert>
      )}
      {partnerEmailStatusMessage && partnerEmailStatusMessage === "pending" && (
        <Alert severity="info">
          {t("warnings.reqPending")}
          {/* Request is pending approval */}
        </Alert>
      )}
      {partnerEmailStatusMessage && partnerEmailStatusMessage === "request" && (
        <Alert severity="info">
          {t("warnings.reqPendingApproval")}
          {/* Request is pending your approval */}
        </Alert>
      )}
    </div>
  );
}
