import React, { useRef, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";

//services
import { loginAPI } from "@services/usersApi";

interface LoginProps {
  setUserId: (val: string) => void;
  setIsRegister: React.Dispatch<React.SetStateAction<boolean>>;
  isRegistered: boolean;
}

const LoginForm = function LoginForm({
  setUserId,
  setIsRegister,
  isRegistered,
}: LoginProps) {
  const emailRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const req = {
      email: emailRef.current?.value,
      password: passwordRef.current?.value,
    };

    loginAPI(req).then((response: any) => {
      if (response && response !== "Incorrect") {
        const user_id = response[0].id;
        setUserId(user_id);
      } else {
        setError(true);
      }
    });
  };

  return (
    <Container component="main" maxWidth="xs" className="loginform">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h3" variant="subtitle1">
          Track your expenses & Manage your finance.
        </Typography>

        <Typography component="h3" variant="caption" align="center">
          ✅100% Free (No Hidden Costs) ✅Safe & Secured <br />
          ✅Simple to use ✅Fast & Optimized ✅No Data Exposure
        </Typography>
        <br />
        <Typography component="h3" variant="button">
          Check out the features on the{" "}
          <a href="https://site.mycashtrack.com/">WEBSITE</a>
        </Typography>
        <br />
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            type="email"
            autoFocus
            inputRef={emailRef}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            inputRef={passwordRef}
          />
          {/* {loginStore.error && (
              <Error>Not sign in. Please check your credentials</Error>
            )} */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Button onClick={(e) => setIsRegister(true)}>
            {"Don't have an account? Sign Up for Free"}
          </Button>
        </Box>
      </Box>
      {error && (
        <Alert severity="error">
          Not sign in. Please check your credentials
        </Alert>
      )}
      {isRegistered && (
        <Alert severity="success">Account created. Please login</Alert>
      )}
    </Container>
  );
};

export default LoginForm;
