import React, { useState, useContext } from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { ThemeContext } from "../../context/themeProvider";

//styles
import styles from "./currencySelector.module.css";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

interface CurrencyProps {
  setCurrency: (value: string | ((val: string) => string)) => void;
  currency: string;
}
export default function CurrencyChange({
  setCurrency,
  currency,
}: CurrencyProps) {
  const theme = useContext(ThemeContext);
  const [checked, setChecked] = useState(
    !currency || (currency && currency === "€") ? false : true
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setCurrency("$");
    } else {
      setCurrency("€");
    }
  };
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{ display: "flex", justifyContent: "center", marginTop: "30px" }}
      alignItems="center"
    >
      <Typography className={`${theme === "dark" && styles.textBlack}`}>
        Euro
      </Typography>
      <AntSwitch
        checked={checked}
        inputProps={{ "aria-label": "ant design" }}
        onChange={handleChange}
      />
      <Typography className={`${theme === "dark" && styles.textBlack}`}>
        Dollar
      </Typography>
    </Stack>
  );
}
