import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

//styles
import styles from "./title.module.css";

interface TitleProps {
  title: string;
  tooltip?: string | null;
}
export default function Header({ title, tooltip }: TitleProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "title-popover" : undefined;

  return (
    <div className={styles.title}>
      {title}
      {tooltip && (
        <>
          <IconButton onClick={handleClick}>
            <InfoIcon />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Typography sx={{ p: 2 }}>{tooltip}</Typography>
          </Popover>
        </>
      )}
    </div>
  );
}
