import React, { useEffect, useState, useContext, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

import { useTranslation } from "react-i18next";

import { UserContext } from "../context/userProvider";
import { ThemeContext } from "../context/themeProvider";

//styles
import styles from "./addExpenses.module.css";

//components
import RadioList from "@components/addExpenses/radioList";
import StepperHead from "@components/addExpenses/stepperHead";
import DatePicker from "@components/addExpenses/datePicker";

//interfaces
import { categories } from "@interfaces/categories";
import { paymentMethods } from "@interfaces/paymentMethods";
import { usersSettings } from "@interfaces/users";

//services
import { getCategoriesAPI } from "@services/categoriesApi";
import { getPaymentMethodAPI } from "@services/paymentMethodApi";
import { setExpensesAPI } from "@services/expensesApi";
import { getSettingsByUserAPI } from "@services/usersApi";

interface Props {
  checkNots: () => Promise<void>;
  currency: string;
}
export function AddExpenses({ checkNots, currency }: Props) {
  const user_id = useContext(UserContext);
  const theme = useContext(ThemeContext);
  const { t, i18n } = useTranslation("common");
  const [visibleState, setVisibleState] = useState(0);
  const [amount, setAmount] = useState<string>();
  const [descr, setDescr] = useState<string>();
  const [categories, setCategories] = useState<categories[]>([]);
  const [selectedCat, setSelectedCat] = useState<string>("1");
  const [paymentMethods, setPaymentMethods] = useState<paymentMethods[]>([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<string>("1");
  const [netSalary, setNetSalary] = useState<string>();
  const [error, setError] = useState(false);
  const [saved, setSaved] = useState(false);
  const [expenseDateTime, setExpenseDateTime] = useState<string | null>("");

  const getUserSettings = useCallback(
    async (categories: categories[]) => {
      // const getUserSettings = (categories: categories[]) => {
      getSettingsByUserAPI(user_id).then((response: usersSettings[]) => {
        if (response && response.length > 0 && response[0].net_worth) {
          setNetSalary(response[0].net_worth);
        }
        if (
          response &&
          response.length > 0 &&
          response[0].preferred_categories
        ) {
          const preferredCatsArray =
            response[0].preferred_categories &&
            response[0].preferred_categories.length > 0
              ? String(response[0].preferred_categories)
                  .split(",")
                  .map((str) => Number(str))
              : [];
          const userSelectedCategories = categories.filter(function (allCat) {
            return preferredCatsArray.some(function (prefCat) {
              return allCat.id === prefCat || allCat.id === 1;
            });
          });

          setCategories(userSelectedCategories);
        }
      });
      // };
    },
    [user_id]
  );

  const getCategories = useCallback(async () => {
    // const getCategories = () => {
    getCategoriesAPI().then((response: categories[]) => {
      if (response && response.length > 0) {
        const sortedCategories = sortCategoriesByName(response);
        setCategories(sortedCategories);
        getUserSettings(sortedCategories);
      }
    });
    // };
  }, [getUserSettings]);

  const getPaymentMethods = useCallback(async () => {
    // const getPaymentMethods = () => {
    getPaymentMethodAPI().then((response: []) => {
      if (response && response.length > 0) {
        setPaymentMethods(response);
      }
    });
    // };
  }, []);

  const sortCategoriesByName = (data: categories[]) => {
    data.sort((a, b) => {
      if (a.name < b.name && a.name !== "Other" && b.name !== "Other") {
        return -1;
      }
      if (a.name > b.name && a.name !== "Other" && b.name !== "Other") {
        return 1;
      }
      return 0;
    });

    return data;
  };

  const handleState = () => {
    if (visibleState === 0 && !amount) {
      setError(true);
    } else {
      setError(false);
      setVisibleState(visibleState + 1);
    }
  };

  const handleSkip = () => {
    if (visibleState === 0 && !amount) {
      setError(true);
    } else {
      setError(false);
      setVisibleState(2);
    }
  };

  const handleSave = () => {
    const req = {
      user_id: user_id,
      category_id: selectedCat,
      payment_way_id: selectedPaymentMethod,
      amount: amount,
      description: descr,
      net_salary: netSalary,
      expense_date: expenseDateTime ? new Date(expenseDateTime) : new Date(),
    };
    setExpensesAPI(req).then((response: []) => {
      setVisibleState(0);
      setSaved(true);
      clearValues();
    });
  };

  const clearValues = () => {
    setAmount("");
    setDescr("");
    setSelectedCat("1");
    setSelectedPaymentMethod("1");
  };

  const handleCloseMessage = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSaved(false);
  };

  useEffect(() => {
    getCategories();
    getPaymentMethods();
    checkNots();
  }, [checkNots, getCategories, getPaymentMethods]);

  const SaveButton = () => {
    if (visibleState !== 2) {
      return (
        <Button variant="contained" onClick={handleState}>
          {/* Next */}
          {t("actions.next")}
        </Button>
      );
    } else {
      return (
        <Button color="success" variant="contained" onClick={handleSave}>
          {/* Save */}
          {t("actions.save")}
        </Button>
      );
    }
  };

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <div className="container__page">
      {saved && (
        <Snackbar
          open={saved}
          autoHideDuration={3000}
          onClose={handleCloseMessage}
        >
          <Alert
            onClose={handleCloseMessage}
            severity="success"
            sx={{ width: "70%" }}
          >
            {t("messages.succesfully")}
            {/* Saved Succesfully! */}
          </Alert>
        </Snackbar>
      )}
      <StepperHead activeStep={visibleState} />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={styles.wrapper}
      >
        {/* its first step */}
        {visibleState === 0 && (
          <>
            <Grid item xs={12} md={2}>
              <DatePicker setExpenseDateTime={setExpenseDateTime} />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                label={t("amount")} //"Amount"
                error={error}
                variant="outlined"
                InputProps={{
                  inputProps: { min: 0 },
                  endAdornment: (
                    <InputAdornment position="end">
                      {currency ? currency : "€"}
                    </InputAdornment>
                  ),
                }}
                type="number"
                value={amount ? amount : ""}
                onChange={(e) => setAmount(e.target.value)}
                style={{ width: "245px" }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextareaAutosize
                minRows={4}
                placeholder={i18n.language === "gr" ? "Σημειώσεις" : "Notes"}
                value={descr ? descr : ""}
                onChange={(e) => setDescr(e.target.value)}
                className={`${styles.textarea} ${
                  theme === "dark" && styles.textareaBlack
                }`}
              />
            </Grid>
          </>
        )}
        {/* its second step categories view*/}
        {visibleState === 1 && (
          <>
            <Grid item xs={12} md={3}>
              <RadioList
                data={categories}
                handleSelection={setSelectedCat}
                label={t("titles.categories")}
                value={selectedCat}
              />
            </Grid>
          </>
        )}
        {/* its third step payment and save view*/}
        {visibleState === 2 && (
          <>
            <Grid item xs={12} md={3}>
              <RadioList
                data={paymentMethods}
                handleSelection={setSelectedPaymentMethod}
                label={t("titles.paymentMethods")}
                value={selectedPaymentMethod}
              />
            </Grid>
          </>
        )}
      </Grid>
      <div className={styles.actionBar}>
        {visibleState !== 0 && (
          <Button
            color="inherit"
            variant="contained"
            onClick={(e) => setVisibleState(visibleState - 1)}
          >
            {t("actions.back")}
          </Button>
        )}
        {visibleState === 0 && (
          <Button size="small" onClick={handleSkip}>
            {t("actions.skipCategory")}
          </Button>
        )}

        <SaveButton />
      </div>
    </div>
  );
}
