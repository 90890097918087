import React, { useContext } from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../context/themeProvider";

//styles
import styles from "./donate.module.css";

export default function Donate() {
  const { t } = useTranslation("common");
  const theme = useContext(ThemeContext);
  const handleClickOpen = () => {
    window.open("https://www.buymeacoffee.com/mycashtrack", "_blank");
    // window.location.href = "https://www.buymeacoffee.com/mycashtrack";
  };

  return (
    <div className={`donate_wrapper ${theme === "dark" && styles.black}`}>
      <Button variant="outlined" onClick={handleClickOpen} size="small">
        {t("donate")} 🍺
      </Button>
    </div>
  );
}
