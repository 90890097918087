import axios from "axios";

import { hostUrl } from "@base/config/config";

export const getPaymentMethodAPI = async () => {
  return axios({
    method: "get",
    url: `${hostUrl}/paymentmethods`,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
