import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import * as EmailValidator from "email-validator";

import { UserContext } from "../context/userProvider";

import { useTranslation } from "react-i18next";

//components
import TranferList from "@components/settings/transferList";
import Title from "@components/common/title";
import LinkAccount from "@components/settings/linkAccount";
import Tabs from "@components/common/tabs";
import CurrencySelector from "@components/settings/currencySelector";
import RecurringExpenses from "@components/settings/recurringExpenses";
// import Categories from "@components/settings/categories";

//services
import { getCategoriesAPI } from "@services/categoriesApi";
import { getPaymentMethodAPI } from "@services/paymentMethodApi";
import {
  getSettingsByUserAPI,
  setSettingsByUserAPI,
  updateUserAPI,
  checkUserPswAPI,
} from "@services/usersApi";
import { getRecExpensesAPI } from "@services/expensesApi";

//interfaces
import { categories } from "@interfaces/categories";
import { paymentMethods } from "@interfaces/paymentMethods";
import { usersSettings } from "@interfaces/users";

//styles
import styles from "./settings.module.css";

const TabsData = [
  { name: "Settings", icon: <ManageAccountsIcon /> },
  { name: "Account", icon: <SettingsSuggestIcon /> },
];

const RecurringExpensesInputs = [
  {
    id: "rec1",
    name: "Recurring Expense",
    row_id: null,
    amount: null,
    date: null,
    payment_ways: [{ label: "", id: 0 }],
    categories: [{ label: "", id: 0 }],
    description: "",
  },
  {
    id: "rec2",
    name: "Recurring Expense",
    row_id: null,
    amount: null,
    date: null,
    payment_ways: [{ label: "", id: 0 }],
    categories: [{ label: "", id: 0 }],
    description: "",
  },
  {
    id: "rec3",
    name: "Recurring Expense",
    row_id: null,
    amount: null,
    date: null,
    payment_ways: [{ label: "", id: 0 }],
    categories: [{ label: "", id: 0 }],
    description: "",
  },
  {
    id: "rec4",
    name: "Recurring Expense",
    row_id: null,
    amount: null,
    date: null,
    payment_ways: [{ label: "", id: 0 }],
    categories: [{ label: "", id: 0 }],
    description: "",
  },
  {
    id: "rec5",
    name: "Recurring Expense",
    row_id: null,
    amount: null,
    date: null,
    payment_ways: [{ label: "", id: 0 }],
    categories: [{ label: "", id: 0 }],
    description: "",
  },
];

interface SettingProps {
  setCurrency: (value: string | ((val: string) => string)) => void;
  currency: string;
  notification: boolean;
}

export function Settings({
  setCurrency,
  currency,
  notification,
}: SettingProps) {
  const user_id = useContext(UserContext);
  const { t } = useTranslation("common");
  const emailRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const passwordCheckRef = useRef<HTMLInputElement | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [wrongPswError, setWrongPswError] = useState(false);
  const [emailExistsError, setEmailExistsError] = useState(false);
  const [netWorth, setNetWorth] = useState<string>();
  const [netWorthError, setNetWorthError] = useState(false);
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(true);
  const [categories, setCategories] = useState<categories[]>();
  const [allcategories, setAllCategories] = useState<categories[]>();
  const [paymentMethods, setPaymentMethods] = useState<paymentMethods[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<categories[]>(
    []
  );
  const [unSelectedCategories, setUnSelectedCategories] = useState<
    categories[]
  >([]);
  const [openMessage, setOpenMessage] = React.useState(false);
  const [savedCategories, setSavedCategories] = useState<categories[]>([]);
  const [tabInView, setTabInView] = useState<number>(0);
  const [missingFields, setMissingFields] = useState(false);
  const [pswLengthError, setPswLengthError] = useState(false);
  const [isValidMailError, setIsValidMailError] = useState(false);
  const [isChangePassValid, setIsChangePassValid] = useState(false);
  const [moreSettingOn, setMoreSettingOn] = useState(false);

  const [currentUserEmail, setCurrentUserEmail] = useState<string>();
  const [partnerEmail, setPartnerEmail] = useState<string>();
  const [partnerEmailStatus, setPartnerEmailStatus] = useState<string>();

  const getUserSettings = useCallback(
    async (categories: categories[]) => {
      getSettingsByUserAPI(user_id).then((response: usersSettings[]) => {
        if (response && response.length > 0) {
          if (response[0].preferred_categories) {
            const preferredCatsArray =
              response[0].preferred_categories &&
              response[0].preferred_categories.length > 0
                ? String(response[0].preferred_categories)
                    .split(",")
                    .map((str) => Number(str))
                : [];

            const userUnSelectedCategories = categories.filter(function (
              allCat
            ) {
              return !preferredCatsArray.some(function (prefCat) {
                return allCat.id === prefCat;
              });
            });
            const userSelectedCategories = categories.filter(function (allCat) {
              return preferredCatsArray.some(function (prefCat) {
                return allCat.id === prefCat;
              });
            });
            setUnSelectedCategories(userUnSelectedCategories);
            setSelectedCategories(userSelectedCategories);
          } else {
            setSelectedCategories(categories);
          }

          if (response[0].net_worth) {
            setNetWorth(response[0].net_worth);
          }
          if (response[0].linked_email_status) {
            setPartnerEmailStatus(response[0].linked_email_status);
          }
          if (response[0].linked_email) {
            setPartnerEmail(response[0].linked_email);
          }
          if (response[0].email) {
            setCurrentUserEmail(response[0].email);
          }
        } else {
          setSelectedCategories(categories);
        }
      });
    },
    [user_id]
  );

  const getCategories = useCallback(async () => {
    getCategoriesAPI().then((response: categories[]) => {
      if (response && response.length > 0) {
        setAllCategories(response);
        const categories = response.filter((category) => category.id !== 1);
        const sortedCategories = sortCategoriesByName(categories);
        setCategories(sortedCategories);
        getUserSettings(sortedCategories);
      }
    });
  }, [getUserSettings]);

  const getPaymentMethods = useCallback(async () => {
    getPaymentMethodAPI().then((response: paymentMethods[]) => {
      if (response && response.length > 0) {
        setPaymentMethods(response);
      }
    });
  }, []);

  const sortCategoriesByName = (data: categories[]) => {
    data.sort((a, b) => {
      if (a.name < b.name && a.name) {
        return -1;
      }
      if (a.name > b.name && a.name) {
        return 1;
      }
      return 0;
    });

    return data;
  };

  const handleSave = () => {
    let catsToBeUpdated = "";
    if (savedCategories && savedCategories.length > 0) {
      savedCategories.forEach(function (category, index) {
        if (index < savedCategories.length - 1) {
          catsToBeUpdated = catsToBeUpdated + category.id + ",";
        } else {
          catsToBeUpdated = catsToBeUpdated + category.id;
        }
      });
    } else {
      selectedCategories.forEach(function (category, index) {
        if (index < selectedCategories.length - 1) {
          catsToBeUpdated = catsToBeUpdated + category.id + ",";
        } else {
          catsToBeUpdated = catsToBeUpdated + category.id;
        }
      });
    }

    const req = {
      id: user_id,
      categories: catsToBeUpdated,
      net_worth: netWorth ? netWorth : null,
    };
    setSettingsByUserAPI(req).then((response) => {
      if (response) {
        setOpenMessage(true);
      }
    });
  };

  const handleNetWorth = (value: string) => {
    if (parseFloat(value) < 0) {
      setNetWorthError(true);
    } else {
      setIsSaveBtnDisabled(false);
      setNetWorth(value);
    }
  };

  const handleCloseMessage = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessage(false);
  };

  const handleCheckUserPsw = () => {
    setWrongPswError(false);
    setEmailExistsError(false);
    if (
      passwordCheckRef.current &&
      passwordCheckRef.current.value &&
      passwordCheckRef.current.value.length < 5
    ) {
      setPswLengthError(true);
      return;
    } else {
      setPswLengthError(false);
    }
    if (passwordCheckRef.current && passwordCheckRef.current.value) {
      const req = {
        id: user_id,
        password: passwordCheckRef.current.value,
      };
      checkUserPswAPI(req).then((response: any) => {
        if (response !== "Incorrect") {
          setIsChangePassValid(true);
          setEmail(response[0].email);
        } else {
          setWrongPswError(true);
        }
      });
    }
  };

  const handleUpdateUser = (event: React.FormEvent<HTMLFormElement>) => {
    setEmailExistsError(false);
    event.preventDefault();
    if (
      passwordRef.current &&
      passwordRef.current.value &&
      passwordRef.current.value.length < 5
    ) {
      setPswLengthError(true);
      return;
    } else {
      setPswLengthError(false);
    }
    if (
      emailRef.current &&
      emailRef.current.value &&
      passwordRef.current &&
      passwordRef.current.value
    ) {
      setMissingFields(false);
      const isEmailValid = EmailValidator.validate(emailRef.current.value);
      if (isEmailValid) {
        setIsValidMailError(false);
        const req = {
          id: user_id,
          email: emailRef.current?.value,
          password: passwordRef.current?.value,
        };

        updateUserAPI(req).then((response: any) => {
          if (response.changedRows === 1) {
            setOpenMessage(true);
          } else {
            setEmailExistsError(true);
          }
        });
      } else {
        setIsValidMailError(true);
      }
    } else {
      setMissingFields(true);
    }
  };

  const handleMoreSettings = () => {
    getRecExpensesAPI({ id: user_id }).then((response) => {
      if (response) {
        response.forEach((element: any, index: number) => {
          RecurringExpensesInputs[index].row_id = element.id;
          RecurringExpensesInputs[index].amount = element.amount;
          RecurringExpensesInputs[index].description = element.description;
          RecurringExpensesInputs[index].date = element.created_at;
          RecurringExpensesInputs[index].payment_ways.push({
            label: element.payment_way_name,
            id: Number(element.payment_way),
          });
          RecurringExpensesInputs[index].categories.push({
            label: element.category_name,
            id: Number(element.category),
          });
        });
      }
      setMoreSettingOn(!moreSettingOn);
    });
  };

  useEffect(() => {
    getCategories();
    getPaymentMethods();
    setMoreSettingOn(false);
    RecurringExpensesInputs.forEach((element: any, index: number) => {
      RecurringExpensesInputs[index].row_id = null;
      RecurringExpensesInputs[index].amount = null;
      RecurringExpensesInputs[index].description = "";
      RecurringExpensesInputs[index].date = null;
      RecurringExpensesInputs[index].payment_ways = [{ label: "", id: 0 }];
      RecurringExpensesInputs[index].categories = [{ label: "", id: 0 }];
    });
  }, [getCategories, getPaymentMethods]);

  useEffect(() => {
    if (moreSettingOn) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [moreSettingOn]);

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <div className="container__page">
      <Tabs tabsData={TabsData} setTabInView={setTabInView} />
      {tabInView === 0 ? (
        <>
          <CurrencySelector setCurrency={setCurrency} currency={currency} />
          <Title
            title={t("titles.categories")}
            tooltip={t("settingsPage.categoriesTooltip")}
          />
          {categories && selectedCategories.length > 0 && (
            <TranferList
              data={selectedCategories}
              unSelectedData={unSelectedCategories}
              setSavedCategories={setSavedCategories}
              setIsSaveBtnDisabled={setIsSaveBtnDisabled}
            />
          )}
          {/* <Title
            title={t("titles.addYourCategories")}
            tooltip={t("settingsPage.RecurringExpenseTooltip")}
          /> */}
          {/* <Categories /> */}
          <Title
            title={t("titles.MonthlyNetSalary")}
            tooltip={t("settingsPage.netTooltip")}
          />
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={styles.wrapper}
          >
            <Grid item xs={6} md={2} sx={{ marginBottom: "10px" }}>
              <TextField
                label={t("settingsPage.netSalary")}
                error={netWorthError}
                variant="outlined"
                InputProps={{
                  inputProps: { min: 0 },
                  endAdornment: (
                    <InputAdornment position="end">
                      {currency ? currency : "€"}
                    </InputAdornment>
                  ),
                }}
                type="number"
                value={netWorth ? netWorth : ""}
                onChange={(e) => handleNetWorth(e.target.value)}
              />
            </Grid>
          </Grid>
          <Button
            className={styles.saveButton}
            variant="contained"
            size="medium"
            disabled={isSaveBtnDisabled}
            onClick={handleSave}
            color="info"
            fullWidth
          >
            {t("actions.save")}
          </Button>

          <Divider />
          <div className={styles.more}>
            {notification ? (
              <Badge badgeContent={1} color="warning">
                <IconButton onClick={handleMoreSettings}>
                  {t("settingsPage.moreSetting")}{" "}
                  <KeyboardArrowDownIcon
                    className={moreSettingOn ? styles.moreSettingVisible : ""}
                  />
                  <span>{t("settingsPage.expandsub")}</span>
                </IconButton>
              </Badge>
            ) : (
              <IconButton onClick={handleMoreSettings}>
                {t("settingsPage.moreSetting")}{" "}
                <KeyboardArrowDownIcon
                  className={moreSettingOn ? styles.moreSettingVisible : ""}
                />
                <span>{t("settingsPage.expandsub")}</span>
              </IconButton>
            )}
          </div>
          {moreSettingOn && (
            <>
              <Title
                title={t("titles.RecurringExpense")}
                tooltip={t("settingsPage.RecurringExpenseTooltip")}
              />
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                className={styles.wrapper}
              >
                <Grid
                  item
                  xs={6}
                  md={2}
                  className={styles.recurWrapper}
                  sx={{ marginBottom: "10px" }}
                >
                  {RecurringExpensesInputs.map((data, index) => (
                    <RecurringExpenses
                      key={data.id}
                      data={data}
                      user_id={user_id}
                      allcategories={allcategories}
                      paymentMethods={paymentMethods}
                      currency={currency}
                      netWorth={netWorth}
                    />
                  ))}
                </Grid>
              </Grid>
              <Title
                title={t("settingsPage.linkPartner")} //"Connect with your partner"
                tooltip={t("settingsPage.linkPartnerTooltip")} //"Share account report with your partner"
              />

              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                className={styles.wrapper}
              >
                <Grid item xs={6} md={2} sx={{ marginBottom: "10px" }}>
                  <LinkAccount
                    partnerEmail={partnerEmail}
                    userId={user_id}
                    currentUserEmail={currentUserEmail}
                    partnerEmailStatus={partnerEmailStatus}
                  />
                </Grid>
              </Grid>
            </>
          )}

          <Snackbar
            open={openMessage}
            autoHideDuration={3000}
            onClose={handleCloseMessage}
          >
            <Alert
              onClose={handleCloseMessage}
              severity="success"
              sx={{ width: "70%" }}
            >
              {t("messages.succesfully")}
              {/* Saved Successfully! */}
            </Alert>
          </Snackbar>
        </>
      ) : (
        // Change account settings
        <>
          {isChangePassValid ? (
            <Box
              component="form"
              onSubmit={handleUpdateUser}
              noValidate
              sx={{ mt: 1, paddingBottom: "100px" }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={t("emailAddress")}
                name="email"
                defaultValue={email}
                type="email"
                autoFocus
                inputRef={emailRef}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={t("Password")}
                type="password"
                id="password"
                autoComplete="current-password"
                inputRef={passwordRef}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {t("actions.save")}
                {/* Save */}
              </Button>

              {isValidMailError && (
                <Alert severity="error">{t("warnings.notValidEmail")}</Alert>
              )}
              {missingFields && (
                <Alert severity="error">{t("warnings.fillFields")}</Alert>
              )}
              {pswLengthError && (
                <Alert severity="error">{t("warnings.pswLength")}</Alert>
              )}
            </Box>
          ) : (
            <>
              <Title
                title={t("titles.accountCreds")}
                tooltip={t("settingsPage.accountTooltip")}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="passwordcheck"
                label={t("settingsPage.verifyPsw")}
                type="password"
                id="passwordcheck"
                inputRef={passwordCheckRef}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleCheckUserPsw}
              >
                {t("actions.submit")}
                {/* Submit */}
              </Button>
              {pswLengthError && (
                <Alert severity="error">{t("warnings.pswLength")}</Alert>
              )}
              {wrongPswError && (
                <Alert severity="error">{t("warnings.wrongPsw")}</Alert>
              )}
            </>
          )}
          <Snackbar
            open={openMessage}
            autoHideDuration={3000}
            onClose={handleCloseMessage}
          >
            <Alert
              onClose={handleCloseMessage}
              severity="success"
              sx={{ width: "70%" }}
            >
              {t("messages.succesfully")}
            </Alert>
          </Snackbar>
          {emailExistsError && (
            <Alert severity="error">{t("warnings.emailExists")}</Alert>
          )}
        </>
      )}
    </div>
  );
}
