import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import moment from "moment";

import { useTranslation } from "react-i18next";

//styles
import styles from "./datePicker.module.css";

interface datePickerProps {
  changeFromDate: (date: string) => void;
  changeToDate: (date: string) => void;
  handleToday: () => void;
  handleLastMonth: () => void;
  handleThisMonth: () => void;
  dateFromPicker: boolean;
  isCurrentMonth: boolean;
  isCurrentDay: boolean;
}

export default function BasicDatePicker({
  changeFromDate,
  changeToDate,
  handleToday,
  handleThisMonth,
  handleLastMonth,
  dateFromPicker,
  isCurrentMonth,
  isCurrentDay,
}: datePickerProps) {
  const { t, i18n } = useTranslation("common");

  const [fromValue, setFromValue] = useState<Date | null>(null);
  const [toValue, setToValue] = useState<Date | null>(null);
  const [selectedButton, setSelectedButton] = useState("1");

  const handleFromDate = (date: Date | null) => {
    setFromValue(date);
    changeFromDate(moment(date).format("YYYY-MM-DD"));
  };

  const handleToDate = (date: Date | null) => {
    setToValue(date);
    changeToDate(moment(date).format("YYYY-MM-DD"));
  };

  const handleButton = (button_id: string) => {
    setSelectedButton(button_id);
    if (button_id === "0") {
      handleToday();
    } else if (button_id === "1") {
      handleThisMonth();
    } else {
      handleLastMonth();
    }
  };

  useEffect(() => {
    if (!dateFromPicker && (isCurrentMonth || isCurrentDay)) {
      setFromValue(null);
      setToValue(null);
    }
  }, [isCurrentMonth, isCurrentDay, dateFromPicker]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.pickers}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MobileDatePicker
            label={t("reportPage.from")} //"From"
            value={fromValue}
            // open={openFromDate}
            inputFormat="DD/MM/YYYY"
            maxDate={new Date()}
            onChange={(newValue) => {
              handleFromDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <MobileDatePicker
            label={t("reportPage.to")} //"To"
            inputFormat="DD/MM/YYYY"
            minDate={fromValue}
            maxDate={new Date()}
            // open={openToDate}
            value={toValue}
            onChange={(newValue) => {
              handleToDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </div>

      <div
        className={`${styles.predifinedPeriods} 
          ${i18n.language === "gr" && styles.smallButtons}`}
      >
        <Button
          variant={
            selectedButton === "0" && !dateFromPicker ? "contained" : "outlined"
          }
          onClick={(e) => handleButton("0")}
        >
          {t("reportPage.Today")}
        </Button>
        <Button
          variant={
            selectedButton === "1" && !dateFromPicker ? "contained" : "outlined"
          }
          onClick={(e) => handleButton("1")}
        >
          {t("reportPage.thisMonth")}
          {/* This Month */}
        </Button>
        <Button
          variant={
            selectedButton === "2" && !dateFromPicker ? "contained" : "outlined"
          }
          onClick={(e) => handleButton("2")}
        >
          {t("reportPage.lastMonth")}
          {/* Last Month */}
        </Button>
      </div>
    </div>
  );
}
