import axios from "axios";
import { hostUrl } from "@base/config/config";

export const getCategoriesAPI = async () => {
  return axios({
    method: "get",
    url: `${hostUrl}/categories`,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
