import React, { useRef, useState } from "react";
import * as EmailValidator from "email-validator";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";

//services
import { registerAPI } from "@services/usersApi";

interface LoginProps {
  setIsRegister: React.Dispatch<React.SetStateAction<boolean>>;
  setIsRegistered: React.Dispatch<React.SetStateAction<boolean>>;
}

const RegisterForm = function RegisterForm({
  setIsRegister,
  setIsRegistered,
}: LoginProps) {
  const emailRef = useRef<HTMLInputElement | null>(null);
  const nameRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState(false);
  const [isValidMailError, setIsValidMailError] = useState(false);
  const [missingFields, setMissingFields] = useState(false);
  const [pswLengthError, setPswLengthError] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      passwordRef.current &&
      passwordRef.current.value &&
      passwordRef.current.value.length < 5
    ) {
      setPswLengthError(true);
      return;
    } else {
      setPswLengthError(false);
    }
    if (
      nameRef.current &&
      nameRef.current.value &&
      emailRef.current &&
      emailRef.current.value &&
      passwordRef.current &&
      passwordRef.current.value
    ) {
      setMissingFields(false);
      const isEmailValid = EmailValidator.validate(emailRef.current.value);
      if (isEmailValid) {
        setIsValidMailError(false);
        const req = {
          name: nameRef.current.value,
          email: emailRef.current?.value,
          password: passwordRef.current?.value,
        };
        registerAPI(req).then((response: any) => {
          if (response && response.insertId !== 0) {
            setIsRegister(false);
            setIsRegistered(true);
            setError(false);
          } else {
            setError(true);
          }
        });
      } else {
        setIsValidMailError(true);
      }
    } else {
      setMissingFields(true);
    }
  };

  return (
    <Container component="main" maxWidth="xs" className="loginform">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Register
        </Typography>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            autoFocus
            inputRef={nameRef}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            type="email"
            inputRef={emailRef}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            inputRef={passwordRef}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Register
          </Button>
          <Button onClick={(e) => setIsRegister(false)}>
            {"Return to login"}
          </Button>
        </Box>
      </Box>
      {error && <Alert severity="error">Email Already exists</Alert>}
      {isValidMailError && <Alert severity="error">Email is not Valid</Alert>}
      {missingFields && (
        <Alert severity="error">Please fill all the fields</Alert>
      )}
      {pswLengthError && (
        <Alert severity="error">
          Your password should have 5 or more characters
        </Alert>
      )}
    </Container>
  );
};

export default RegisterForm;
