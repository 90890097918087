import React, { useEffect, useState, useContext, useCallback } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import moment from "moment";
import * as XLSX from "xlsx";

import { UserContext } from "../context/userProvider";

import { useTranslation } from "react-i18next";

//components
import Table from "@components/history/table";
import MonthsList from "@components/history/monthsHistory";

//services
import {
  getExpensesAPI,
  deleteExpenseAPI,
  deleteExpensesAPI,
  getExpensesSumReportByMonthAPI,
} from "@services/expensesApi";

//styles
import styles from "./history.module.css";

interface SpreadSheetData {
  AMOUNT: string;
  DESCRIPTION: string;
  CATEGORY: string;
  PAYMENT_METHOD: string;
  DATE: string;
}

const SpreadSheetColumns: (keyof SpreadSheetData)[] = [
  "AMOUNT",
  "DESCRIPTION",
  "CATEGORY",
  "PAYMENT_METHOD",
  "DATE",
];

interface Props {
  checkNots: () => void;
  currency: string;
}
export function History({ checkNots, currency }: Props) {
  const user_id = useContext(UserContext);
  const { t } = useTranslation("common");
  const [expenses, setExpenses] = useState<any>([]);
  const [expensesByMonth, setExpensesByMonth] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const getExpenses = useCallback(async () => {
    // const getExpenses = () => {
    const req = {
      id: user_id,
      limit: 100,
    };
    getExpensesAPI(req).then((response: []) => {
      if (response && response.length > 0) {
        setExpenses(response);
      }
      setLoading(false);
    });

    getExpensesSumReportByMonthAPI(req).then((response: []) => {
      if (response && response.length > 0) {
        setExpensesByMonth(response);
      }
    });
    // };
  }, [user_id]);

  const handleDelete = (ids: any) => {
    setLoading(true);
    if (ids && ids.length > 1) {
      const req = {
        user_id: user_id,
        expenses_id: ids,
      };
      deleteExpensesAPI(req).then((response: any) => {
        if (response && response.affectedRows >= 1) {
          getExpenses();
          setOpen(true);
        }
        setLoading(false);
      });
    } else {
      const req = {
        user_id: user_id,
        expenses_id: ids,
      };
      deleteExpenseAPI(req).then((response: any) => {
        if (response && response.affectedRows === 1) {
          getExpenses();
          setOpen(true);
        }
        setLoading(false);
      });
    }
  };

  const handleDownload = () => {
    const req = {
      id: user_id,
      limit: 500,
    };
    // sleep(4000).then(() => {
    getExpensesAPI(req).then((response: []) => {
      if (response && response.length > 0) {
        downloadXlsx(response);
      }
    });
    // });
  };
  const docsToBeExporteddata: SpreadSheetData[] = [];
  const downloadXlsx = (data: any) => {
    data?.forEach((expense: any) => {
      const amount = expense.amount;
      const description = expense.description ? expense.description : "-";
      const category = expense.category_name;
      const paymentMethod = expense.payment_way_name;
      const date = moment(expense.created_at).format("DD/MM/YYYY");

      docsToBeExporteddata.push({
        AMOUNT: amount,
        DESCRIPTION: description,
        CATEGORY: category,
        PAYMENT_METHOD: paymentMethod,
        DATE: date,
      });
    });

    const ws = XLSX.utils.json_to_sheet(docsToBeExporteddata, {
      header: SpreadSheetColumns,
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Expenses");
    XLSX.writeFile(wb, "Expenses.xlsx");
  };

  useEffect(() => {
    getExpenses();
    checkNots();
  }, [checkNots, getExpenses]);

  return (
    <div className="container__page">
      {loading ? (
        <div className={styles.loadingWrapper}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {expensesByMonth && expensesByMonth.length > 0 && (
            <MonthsList data={expensesByMonth} currency={currency} />
          )}
          <Table
            rows={expenses}
            handleDelete={handleDelete}
            handleDownload={handleDownload}
            currency={currency}
          />
          <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "70%" }}
            >
              {t("messages.deleted")}
              {/* Deleted Succesfully */}
            </Alert>
          </Snackbar>
        </>
      )}
    </div>
  );
}
