import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./fonts/Inter/Inter-VariableFont_slnt,wght.ttf";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import ReactGA from "react-ga4";
import common_en from "./translations/en/common.json";
import common_gr from "./translations/gr/common.json";

ReactGA.initialize("G-B6H4V0K9FE");
const lang = localStorage.getItem("lang");

i18next.init({
  interpolation: { escapeValue: false },
  lng: lang ? JSON.parse(lang) : "en", // language to use
  resources: {
    en: {
      common: common_en,
    },
    gr: {
      common: common_gr,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>
);

serviceWorkerRegistration.register();
