import React, { useContext } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { ThemeContext } from "../../context/themeProvider";

import { useTranslation } from "react-i18next";

//styles
import styles from "./monthsHistory.module.css";

interface MonthsListProps {
  data: any;
  currency: string;
}
export default function MonthsList({ data, currency }: MonthsListProps) {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation("common");

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <List className={styles.headerList}>
        <ListItem className={`${theme === "dark" && styles.darkList}`}>
          <ListItemText primary={t("historyPage.Month")} />
          <ListItemText
            sx={{ textAlign: "right" }}
            primary={t("historyPage.TotalExpenses")} //{"Total Expenses"}
          />
        </ListItem>
      </List>
      <List>
        {data.map((row: any) => (
          <ListItem
            key={row["MONTHNAME(created_at)"]}
            className={`${theme === "dark" && styles.darkList}`}
          >
            <ListItemText primary={row["MONTHNAME(created_at)"]} />
            <ListItemText
              sx={{ textAlign: "right" }}
              primary={row["SUM(amount)"] + (currency ? currency : "€")}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
