import React from "react";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import TuneIcon from "@mui/icons-material/Tune";
import mainLogo from "@assets/logo.png";
import Badge from "@mui/material/Badge";

//components
import DarkMode from "@components/common/darkMode";
import LangSelector from "@components/common/languagesSelector";
//styles
import styles from "./header.module.css";

interface headerProps {
  setUserId: (value: string | ((val: string) => string)) => void;
  setTheme: (value: any) => void;
  theme: any;
  notification: boolean;
}
export default function Header({
  setUserId,
  setTheme,
  theme,
  notification,
}: headerProps) {
  return (
    <div className={styles.header}>
      <Link to="/">
        <div className={styles.logo}>
          <img alt="logo" src={mainLogo} width="65" />
          My Cash Track
        </div>
      </Link>
      <div className={styles.rightSide}>
        <div className={styles.topUpper}>
          <LangSelector />
          <DarkMode setTheme={setTheme} theme={theme} />
        </div>
        {/* <DarkMode setTheme={setTheme} theme={theme} /> */}
        {notification ? (
          <Badge sx={{ right: "7px" }} badgeContent={1} color="warning">
            <IconButton
              className={styles.settings}
              component={Link}
              to={"/settings"}
            >
              <TuneIcon />
            </IconButton>
          </Badge>
        ) : (
          <IconButton
            className={styles.settings}
            component={Link}
            to={"/settings"}
          >
            <TuneIcon />
          </IconButton>
        )}

        <IconButton className={styles.logout} onClick={(e) => setUserId("")}>
          <LogoutIcon />
        </IconButton>
      </div>
    </div>
  );
}
